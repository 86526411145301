<template>
  <v-card elevation="0" color="transparent" class="ma-3">
    <v-row class="d-flex align-center justify-center">
      <v-col v-for="(item, index) in items" :key="index" class="d-flex child-flex" cols="auto">

        <v-card class="fill-height" :min-width="size" @click="item.driveId ? loadDialog(item) : null"
          :ripple="item.driveId ? true : false" :style="'cursor: ' + (item.driveId ? 'pointer' : 'auto')"
          color="grey-lighten-2">

          <Image :height="size" :width="size" :source="'data:image/webp;base64,' + item.img.Data.toString('base64')"
            :text="item.title || 'Sztuka Macieja Paździora'" />

        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Image from '@/components/Image.vue'

export default {
  name: 'Gallery',
  props: ['kind'],
  data: () => ({
    size: "250px",
  }),
  components: {
    Image,
  },
  computed: {
    items() {
      return this.$store.getters['get' + this.kind + 'State']
    },
  },
  methods: {
    loadDialog(item) {
      this.$store.dispatch('changeArtDialogItem', item)
    },
  },
  async created() {
    await this.$store.dispatch('fetch' + this.kind)
  },
}
</script>
