<template>
  <Presentation />
</template>


<script>
import Presentation from '@/components/Presentation'

export default {
  name: 'Poetry',
  data: () => ({
  }),
  components: {
    Presentation,
  },
  async created() {
    await this.$store.dispatch('fetchProjects')
  },
};
</script>
