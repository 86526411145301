<template>
  <v-card height="100vh" width="100vw" class="text-white text-center justify-center d-flex flex-column rounded-0"
    fill-height>
    <template v-slot:image>
      <ImageHome />
    </template>
    <p class="mt-12 font-weight-bold" :class="$vuetify.display.xs ? 'text-h3' : 'text-h1'">{{ title }}</p>
    <p class="mt-12 mx-2 text-overline">{{ motto }}</p>
    <p class="mt-12 text-overline">{{ button }}</p>
    <v-spacer></v-spacer>
    <v-btn href="/#/paintings" icon="mdi-arrow-right-thick" class="mb-12 ml-auto mx-5 font-weight-bold text-black"
      style="font-size: 2em" x-large size="75" aria-label="Przejdź dalej"></v-btn>
  </v-card>
</template>

<script>
import ImageHome from '@/components/ImageHome.vue'
import content from '@/content.json'

export default {
  name: 'Home',
  data: () => ({
    title: content.home.title,
    motto: content.home.motto,
    button: content.home.invitation,
  }),
  components: {
    ImageHome,
  },
};
</script>
