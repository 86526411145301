<template>
  <div v-for="(section, name) in sections" :key="name">
    <p class="text-h4 font-weight-bold ma-5 text-grey-darken-3">{{ section }}</p>
    <Gallery class="mb-16" :kind="name" />
  </div>
</template>


<script>
import Gallery from '@/components/Gallery'

export default {
  name: 'Projects',
  data: () => ({
    sections: {
      Monuments: 'Pomniki',
      Recitals: 'Recitale',
      Others: 'Pozostałe',
    }
  }),
  components: {
    Gallery,
  },
  async created() {
    await this.$store.dispatch('fetchProjects')
  },
};
</script>
