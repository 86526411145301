<template>
  <v-app-bar v-if="($route.name === 'paintings') || $vuetify.display.mdAndDown" elevation="0">
    <template v-slot:prepend>
      <v-app-bar-nav-icon v-if="$vuetify.display.mdAndDown" variant="text"
        @click.stop="$store.commit('changeDrawer')"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title v-if="$route.name === 'paintings'">
      <v-carousel v-model="dialog.selectedIndex" height="50" hide-delimiters touch>
        <v-carousel-item v-for="(year, index) in dialog.years" :key="index">
          <v-sheet height="100%">
            <div class="d-flex fill-height justify-center align-center">
              <div class="text-h5">
                {{ year.replace(/-/g, ' - ') }}
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-app-bar-title>

    <template v-slot:append v-if="$route.name === 'paintings'">
      <v-btn @click="$store.commit('changeYearsDialog', true)" icon>
        <v-icon>mdi-dots-vertical</v-icon>
        <YearsDialog />
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import YearsDialog from '@/components/YearsDialog'

export default {
  name: 'TopBar',
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      dialog: 'getYearsDialogState'
    })
  },
  methods: {
  },
  components: {
    YearsDialog,
  },
  watch: {
    '$store.state.yearsDialog.selectedIndex'() {
      let year = this.dialog.years[this.dialog.selectedIndex]
      this.$store.dispatch('fetchPaintings', year.replace(/-/g, '_'))
    }
  },
}
</script>
