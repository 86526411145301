<template>
  <v-card :class="$vuetify.display.xs ? 'mx-1' : 'mx-16'" fill-height elevation="0">
    <v-row align="center" justify="center">
      <v-col class="ma-16">
        <p v-for="(part, index) in text" :key="index" class="my-4">{{ part }}</p>
      </v-col>

      <v-col v-if="$vuetify.display.xl" class="ma-16" cols="auto">
        <v-row class="mb-1">
          <Image height="288px" width="288px" :source="photo" text="Maciej Paździor" />
        </v-row>
        <v-row>
          <Image height="288px" width="288px" :source="painting" text="Obraz Wędrowiec" />
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="!$vuetify.display.xl" class="ma-10">
      <v-col :align="$vuetify.display.smAndDown ? '' : 'right'">
        <Image height="288px" width="288px" :source="photo" text="Maciej Paździor" />
      </v-col>
      <v-col cols="auto">
        <Image height="288px" width="288px" :source="painting" text="Obraz Wędrowiec" />
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import Image from '@/components/Image'
import content from '@/content.json'

export default {
  name: 'About',
  data: () => ({
    text: content.about,
    photo: require('@/assets/imgs/about-photo.webp'),
    painting: require('@/assets/imgs/about-painting.webp'),
  }),
  components: {
    Image,
  },
};
</script>
