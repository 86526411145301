<template>
  <v-img :height="height" :width="width" lazy-src="@/assets/imgs/blank.webp" :src="source" :alt="text" :cover="isCover">
    <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
      </div>
    </template>
  </v-img>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    height: String,
    width: String,
    source: String,
    text: String,
    isCover: {
      type: Boolean,
      default: true
    }
  },
}
</script>
