<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-card elevation="0" align="center">
        <v-card-title class="text-h2 mb-5">404</v-card-title>
        <v-card-text class="text-overline">Strona nie została znaleziona</v-card-text>
        <v-btn variant="text" href="/">powrót</v-btn>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
