<template>
  <Gallery :kind="$options.name"/>
</template>


<script>
import Gallery from '@/components/Gallery'

export default {
  name: 'Paintings',
  data: () => ({
  }),
  components: {
    Gallery,
  },
  async created() {
    await this.$store.dispatch('fetchYears')
    this.$store.commit('changeSelectedYear', '2023')
  }
};
</script>
