<template>
  <v-img :height="height" :width="width" lazy-src="@/assets/imgs/home-blur.webp" :src="require(`@/${imagePath}${image}`)" alt="Obraz Wędrowiec" cover>
    <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
      </div>
    </template>
  </v-img>
</template>

<script>
export default {
  name: 'ImageHome',
  data: () => ({
    imagePath: 'assets/imgs/home-',
  }),
  computed: {
    image() {
      switch (this.$vuetify.display.name) {
        case 'smAndDown': return 'sm.webp'
        case 'xlAndUp': return 'xl.webp'
        default: return 'lg.webp'
      }
    },
  },
};
</script>
