<template>
  <v-dialog v-model="artDialog.isActive" fullscreen class="fill-height" @click:outside="closeDialog" @keydown.esc="closeDialog">
    <v-card color="grey-lighten-4">
      <v-btn @click="closeDialog">Zamknij</v-btn>
      <iframe v-if="artDialog.data.driveId"
        :src="'https://workdrive.zohopublic.eu/embed/' + artDialog.data.driveId + '?toolbar=false&appearance=light&themecolor=green'"
        scrolling="no" frameborder="0" allowfullscreen=true height="100%" width="100%"></iframe>

      <div class="my-5">
        <v-row class="justify-center text-center mx-10 mb-2 font-weight-bold">
          <p v-if="artDialog.data.title">
            {{ artDialog.data.title }}          
          </p>
        </v-row>

        <v-card-text v-if="artDialog.data.description">
          <v-row class="justify-center" v-for="line in artDialog.data.description" :key="line">
            {{ line }}
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: "ArtDialog",
  computed: {
    ...mapGetters({
      artDialog: 'getArtDialogState'
    })
  },
  methods: {
    closeDialog() {
      this.$store.commit('changeArtDialog', false)
    },
  },
}
</script>

<style>
.v-card-title {
white-space: normal; /* maybe !important */
}
</style>
