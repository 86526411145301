<template>
  <Gallery :kind="$options.name"/>
</template>


<script>
import Gallery from '@/components/Gallery'

export default {
  name: 'Portraits',
  data: () => ({
  }),
  components: {
    Gallery,
  },
};
</script>
