<template>
  <v-dialog v-model="dialog.isActive" fullscreen class="fill-height" @click:outside="closeDialog"
    @keydown.esc="closeDialog">
    <v-card color="grey-lighten-4">
      <template v-slot:image>
        <ImageHome />
      </template>
      <v-btn @click="closeDialog">Zamknij</v-btn>

      <div class="ma-5">
        <v-card-title class="text-center text-white font-weight-bold">OBRAZY {{ dialog.years[dialog.selectedIndex]
        }}</v-card-title>

        <v-card-subtitle class="text-center text-black font-weight-bold text-overline">Powędruj w
          lata</v-card-subtitle>

        <v-card-text>

          <v-row class="d-flex align-center justify-center">
            <v-col v-for="(year, index) in dialog.years" :key="index" class="d-flex child-flex" cols="4">

              <v-btn @click="this.selectYear(year)" :disabled="isSelected(year)" rounded="xl" block>
                {{ year }}
              </v-btn>
            </v-col>
          </v-row>



        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>


<script>
import ImageHome from '@/components/ImageHome.vue'
import { mapGetters } from 'vuex'

export default {
  name: "YearsDialog",
  computed: {
    ...mapGetters({
      dialog: 'getYearsDialogState'
    })
  },
  methods: {
    isSelected(year) {
      return this.dialog.years[this.dialog.selectedIndex] === year;
    },
    selectYear(year) {
      console.log(year);
      this.$store.commit('changeSelectedYear', year);
      this.closeDialog();
    },
    closeDialog() {
      this.$store.commit('changeYearsDialog', false);
    },
  },
  components: {
    ImageHome,
  }
}
</script>
