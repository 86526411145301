<template>
  <v-navigation-drawer v-model="$store.state.drawer" :permanent="!$vuetify.display.mdAndDown">
    <v-row class="ma-14 align-center justify-center" v-if="!$vuetify.display.xs">
      <v-avatar size="96px">
        <Image :source="require('@/assets/imgs/author.webp')" text="Zdjęcie Macieja Paździora" />
      </v-avatar>
    </v-row>

    <v-row class="mt-2 align-center justify-center">
      <v-list nav dense>
        <v-list-item v-for="item in menu" :key="item.title" :href="'#/' + item.view" class="text-center">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-row>

    <template v-slot:append>
      <div class="my-16">
        <v-divider class="ma-6"></v-divider>
        <v-row align="center" justify="center" style="font-size: 0.8em; font-weight: 900;">© {{ new Date().getFullYear()
        }}
          Maciej Paździor</v-row>
        <v-row align="center" justify="center" style="font-size: 0.8em;">maciej@pazdzior.art</v-row>
        <v-row align="center" justify="center" style="font-size: 0.7em;">Wykonanie:
          <v-btn class="ma-0 pa-1" style="font-size: 0.8em;" href="https://niec.me/" target="_blank"
            variant="plain">Adrian Nieć</v-btn></v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import content from '@/content.json'
import Image from '@/components/Image';

export default {
  name: 'Navigation',
  data: () => ({
    menu: content.nav.menu,
  }),
  components: {
    Image,
  },
  watch: {
    '$vuetify.display.mdAndDown'() {
      if (!this.$vuetify.display.mdAndDown) {
        this.$store.commit('setDrawer', true)
      }
    }
  },
  created() {
    let drawerState = true
    if (this.$vuetify.display.mdAndDown) {
      drawerState = false
    }
    this.$store.commit('setDrawer', drawerState)
  },
}
</script>
