<template>
  <v-carousel v-model="selectedItem" height="100vh" hide-delimiters progress="blue-grey-lighten-3">
    <v-carousel-item v-for="(item, index) in items" :key="index">

      <v-card height="100%" tile elevation="0">
        <v-row class="d-flex fill-height justify-center align-center">
          <div v-if="!isReversed || (!item.subtitle && !item.description)" class="">
            <v-row>
              <Image :height="size" :width="size" :source="'data:image/webp;base64,' + item.img.Data.toString('base64')"
                :text="item.title || 'Sztuka Macieja Paździora'" :isCover="false" />
            </v-row>

            <v-row class="justify-center text-center mx-10 mt-12 font-weight-bold">
              <p v-if="item.title">
                {{ item.title }}
              </p>
            </v-row>
            <v-row v-if="item.subtitle && item.description" class="justify-center text-center ma-5">
              <v-btn @click="isReversed = !isReversed" variant="text">Poczytaj</v-btn>
            </v-row>
          </div>

          <div v-if="isReversed" class="my-5">
            <v-row class="justify-center text-center mx-10 mb-2 font-weight-bold">
              <p v-if="item.subtitle">
                {{ item.subtitle }}
              </p>
            </v-row>

            <v-card-text v-if="item.description">
              <v-row class="justify-center" v-for="line in item.description" :key="line">
                {{ line }}
              </v-row>
            </v-card-text>

            <v-row v-if="item.subtitle && item.description" class="justify-center text-center ma-5">
              <v-btn @click="isReversed = !isReversed" variant="text">Wróć</v-btn>
            </v-row>
          </div>
        </v-row>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import Image from '@/components/Image.vue'

export default {
  name: 'Presentation',
  data: () => ({
    selectedItem: 0,
    isReversed: false,
    size: "60vh",
  }),
  components: {
    Image,
  },
  computed: {
    items() {
      return this.$store.getters['getPoetryState']
    },
  },
  methods: {
    loadDialog(item) {
      this.$store.dispatch('changeArtDialogItem', item)
    },
  },
  async created() {
    await this.$store.dispatch('fetch' + this.kind)
  },
}
</script>
