<template>
  <v-app>
    <v-main>
      <Navigation v-if="$route.name !== 'home'" />
      <TopBar v-if="$route.name !== 'home'" />
      <ArtDialog v-if="$store.state.artDialog.isActive" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import ArtDialog from '@/components/ArtDialog.vue'
import TopBar from '@/components/TopBar.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    ArtDialog,
    TopBar,
  },
}
</script>
